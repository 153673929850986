import { createRouter, createWebHistory } from "vue-router";
import Api from "./Api";
// import { captureException } from "@sentry/browser";
import RoleEnums from "./enums";
import store from "./store";

// console.log("user already logged in: " + localStorage.loggedIn);
// if (typeof (Storage) !== "undefined") {
//     if (localStorage.loggedIn && localStorage.loggedIn === "true") {
//         store.dispatch("loggedIn");
//     }
//     if (localStorage.loggedIn && localStorage.loggedIn === "false") {
//         store.dispatch("loggedOut");
//     }
// }

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  }
  const position = {};
  // new navigation.
  // scroll to anchor by returning the selector
  if (to.hash) {
    // position.selector = to.hash
  }
  // check if any matched route config has meta that requires scrolling to top
  if (to.matched.some((m) => m.meta.scrollToTop)) {
    // cords will be used if no selector is provided,
    // or if the selector didn't match any element.
    position.x = 0;
    position.y = 0;
  }
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  return position;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes: [
    {
      path: "/LiveClasses",
      component: () => import(/* webpackChunkName: "live-classes" */ "./views/LiveClasses"),
      name: "LiveClasses",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/ManageChapterAccess",
      component: () =>
        import(/* webpackChunkName: "manage-chapter-access" */ "./views/ManageChapterAccess"),
      name: "ManageChapterAccess",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/TestAnalysisUserReport",
      component: () => import(/* webpackChunkName: "upload" */ "./views/TestAnalysisUserReport"),
      name: "TestAnalysisUserReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/PracticeAnalysis_dew",
      component: () =>
        import(/* webpackChunkName: "practice-analysis" */ "./views/PracticeAnalysis_dew"),
      name: "PracticeAnalysis_dew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/PracticeAnalysisUserReport",
      component: () =>
        import(/* webpackChunkName: "practice-analysis" */ "./views/PracticeAnalysisUserReport"),
      name: "PracticeAnalysisUserReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/PracticeAnalysis",
      component: () =>
        import(/* webpackChunkName: "practice-analysis" */ "./views/PracticeAnalysis"),
      name: "PracticeAnalysis",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/TestAnalysisReport",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/TestAnalysisReport"),
      name: "TestAnalysisReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/TestAnalysis",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/TestAnalysis"),
      name: "TestAnalysis",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/WeeklyPlanner",
      component: () => import(/* webpackChunkName: "weekly-planner" */ "./views/WeeklyPlanner"),
      name: "WeeklyPlanner",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/StudentDashboard",
      component: () =>
        import(/* webpackChunkName: "student-dashboard" */ "./views/student/StudentDashboard"),
      name: "StudentDashboard",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/SubjectCoverage",
      component: () => import(/* webpackChunkName: "subject-coverage" */ "./views/SubjectCoverage"),
      name: "SubjectCoverage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/CreateLiveClassV1",
      component: () => import("./views/affiliation/createLiveClass"),
      name: "CreateLiveClassV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/EditLiveClass",
      component: () => import("./views/affiliation/createLiveClass"),
      name: "EditLiveClass",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/CreateLivePracticeSession",
      component: () => import("./views/affiliation/createLiveClass"),
      name: "CreateLivePracticeSession",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/EditLivePracticeSession",
      component: () => import("./views/affiliation/createLiveClass"),
      name: "EditLivePracticeSession",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/StudyMaterial",
      component: () => import("./views/StudyMaterial"),
      name: "StudyMaterial",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/AffiliationCreateUser",
      component: () => import("./views/AffiliationCreateUser"),
      name: "AffiliationCreateUser",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/EditUser",
      component: () => import("./views/AffiliationCreateUser"),
      name: "EditUser",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/Notice",
      component: () => import("./views/Notice"),
      name: "Notice",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/NoticeLinkHistory",
      component: () => import("./views/NoticeLinkHistory"),
      name: "NoticeLinkHistory",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/AssignmentReport",
      component: () => import("./views/AssignmentReport"),
      name: "AssignmentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/NewU",
      component: () => import("./views/NewU"),
      name: "NewU",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/DoubtReport",
      component: () => import("./views/DoubtReport"),
      name: "DoubtReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/PracticeSessionReport",
      component: () => import("./views/PracticeSessionReport"),
      name: "PracticeSessionReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.Student,
          RoleEnums.DemoStudent,
        ],
      },
    },
    {
      path: "/EvaluateSubjectiveQuestions",
      component: () => import("./views/EvaluateSubjectiveQuestions"),
      name: "EvaluateSubjectiveQuestions",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/AffiliationReport",
      component: () => import("./views/AffiliationReport"),
      name: "AffiliationReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/Dashboard",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/student/StudentDashboard"),
      name: "Dashboard",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/BatchReport",
      component: () => import("./views/BatchReport"),
      name: "BatchReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/TeacherFeedBack",
      component: () => import("./views/TeacherFeedBack"),
      name: "TeacherFeedBack",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/ConductTest/:testUserId/:testType/",
      component: () => import(/* webpackChunkName: "conduct-test" */ "./views/ConductTestNewUi"),
      name: "ConductTest",
      meta: {
        hideNavbar: true,
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
      props: true,
    },
    {
      path: "/ConductTest_SB/:testUserId/:testType/",
      component: () => import(/* webpackChunkName: "conduct-test" */ "./views/ConductTest_SB"),
      name: "ConductTest_SB",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
      props: true,
    },
    {
      path: "/ConductPreloadedTest/:preloadedTestUserId",
      component: () =>
        import(/* webpackChunkName: "conduct-test" */ "./views/student/ConductPreloadedTest"),
      name: "ConductPreloadedTest",
      meta: {
        hideNavbar: true,
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
      props: true,
    },
    {
      path: "/ReviseQuestions",
      component: () => import("./views/ReviseQuestions.vue"),
      name: "ReviseQuestions",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.Faculty],
      },
    },

    {
      path: "/QuestionRevisionQuiz",
      component: () => import("./views/QuestionRevisionQuiz.vue"),
      name: "QuestionRevisionQuiz",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.Faculty],
      },
    },
    {
      path: "/TutorHelp",
      name: "TutorHelp",
      component: () => import("./views/TutorHelp.vue"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.Faculty],
      },
    },

    {
      path: "/TutorHelpQuestionView",
      name: "TutorHelpQuestionView",
      component: () => import("./views/TutorHelpQuestionView.vue"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.Faculty],
      },
    },
    {
      path: "/TestReport/:testUserId/:testType",
      component: () =>
        import(/* webpackChunkName: "test-report" */ "./views/common/studentTestReport.vue"),
      name: "TestReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
      props: true,
    },
    {
      path: "/MyProfile",
      component: () => import(/* webpackChunkName: "home" */ "./views/MyProfile"),
      name: "MyProfile",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/NotAuthorized",
      component: () => import(/* webpackChunkName: "not-authorized" */ "./views/NotAuthorized"),
      name: "NotAuthorized",
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/Manageuser",
      component: () => import("./views/Manageuser"),
      name: "Manageuser",
    },
    {
      path: "/sso",
      name: "SSO",
    },
    {
      path: "/EffortAnalysisNew",
      component: () =>
        import(/* webpackChunkName: "effort-analysis-new" */ "./views/EffortAnalysisNew"),
      name: "EffortAnalysisNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/Logout",
      name: "Logout",
    },
    {
      path: "/:catchAll(.*)",
      component: () => import(/* webpackChunkName: "page-not-found" */ "./views/PageNotFound"),
      name: "PageNotFound",
      meta: {
        scrollToTop: true,
      },
    },
    {
      path: "/ChangePassword",
      component: () => import(/* webpackChunkName: "home" */ "./views/ChangePassword"),
      name: "ChangePassword",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/AffiliationDashboard",
      component: () => import("./views/affiliation/dashboard"),
      name: "AffiliationDashboard",
      meta: {
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/Batch",
      component: () => import("./views/Batch"),
      name: "Batch",
      meta: {
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/Student",
      component: () => import("./views/Student"),
      name: "Student",
      meta: {
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/GeneralReport",
      component: () => import("./views/GenricReport"),
      name: "GenricReport",
    },
    {
      path: "/CreateAssignment",
      component: () => import("./views/CreateAssignmentNewV1"),
      name: "CreateAssignment",
      meta: {
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/StudentReport",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/StudentReport"),
      name: "StudentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/StudentDoubtReport",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/StudentDoubtReport"),
      name: "StudentDoubtReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/InstituteTests",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestListN"),
      name: "InstituteTests",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/InstituteTestResult",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestResult"),
      name: "InstituteTestResult",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/PsychometryTest",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PsychometryTest"),
      name: "PsychometryTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AffiliationFacultyList",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/AffiliationFacultyList"),
      name: "AffiliationFacultyList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/SelectInstituteTest",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/SelectInstituteTestNew"),
      name: "SelectInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/ViewReport",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/ViewReport"),
      name: "ViewReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/StudentFeedback",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/StudentFeedback"),
      name: "StudentFeedback",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/dppsession",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/UserDppSession"),
      name: "DppSession",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AffiliationPayment",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/AffiliationPayment"),
      name: "AffiliationPayment",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/AffiliationProfile",
      component: () => import("./views/AffiliationProfile"),
      name: " AffiliationProfile",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/AffiliationbuyLogin",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/AffiliationbuyLogin"),
      name: "AffiliationbuyLogin",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/AffiliationloginQuantity",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/AffiliationloginQuantity"),
      name: "AffiliationloginQuantity",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/LearnVideos",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/LearnVideos"),
      name: "LearnVideos",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/LearnConcept",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/LearnConcept"),
      name: "LearnConcept",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/LearnStudyMaterial",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/LearnStudyMaterial"),
      name: "LearnStudyMaterial",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/previewPage",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/previewPage"),
      name: "previewPage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/PracticeCoverage",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PracticeCoverage"),
      name: "PracticeCoverage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/ChapterDashboard",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/ChapterDashboard"),
      name: "ChapterDashboard",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/InstituteTestCoverage",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestCoverage"),
      name: "InstituteTestCoverage",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/PastWeekPractice",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PastWeekPractice"),
      name: "PastWeekPractice",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/InstituteTestPerformance",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/InstituteTestPerformance"),
      name: "InstituteTestPerformance",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Faculty, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/Homework",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/Homework"),
      name: "Homework",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/ExamSession",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/ExamSession"),
      name: "ExamSession",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/SelfTestHistoryNew",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/SelfTestHistoryNew"),
      name: "SelfTestHistoryNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/FLTList",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/FLTList"),
      name: "FLTList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AddCustomQuestion",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/AddCustomQuestion"),
      name: "AddCustomQuestion",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/Teaching",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/Teaching"),
      name: "Teaching",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.Student,
          RoleEnums.DemoStudent,
        ],
      },
    },
    {
      path: "/MergeInstituteTest",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/MergeInstituteTest"),
      name: "MergeInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/PracticeSession",
      component: () => import("./views/PracticeSession"),
      name: "PracticeSession",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/PracticeHome",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/PracticeHome"),
      name: "PracticeHome",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/CoachingVideo",
      component: () => import("./views/CoachingVideo"),
      name: "CoachingVideo",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/ActionInstituteTestNew",
      component: () => import("./views/affiliation/manageInstituteTest"),
      name: "ManageInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/ClassLogList",
      component: () => import("./views/ClassLogList"),
      name: "ClassLogList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/CustomQuestionList",
      component: () =>
        import(/* webpackChunkName: "add-institute-test" */ "./views/CustomQuestionList"),
      name: "CustomQuestionList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/psychometry-quiz-question",
      name: "Psychometry Question",
      component: () => import("./views/PsychometryTestQuestion"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, "Student"],
      },
    },
    {
      path: "/SharedTestListNew",
      component: () =>
        import(/* webpackChunkName: "add-student-test" */ "./views/SharedTestListNew"),
      name: "SharedTestListNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/subjectiveevaluation",
      name: "Subjective Evaluation",
      component: () => import("./views/SubjectiveEvaluation1"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/testevaluation",
      name: "Test Evaluation",
      component: () => import("./views/TestEvaluation"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/AssignmentAnalysis",
      name: "AssignmentAnalysis",
      component: () => import("./views/AssignmentAnalysis"),
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/StudentAssignmentReport",
      component: () => import("./views/StudentAssignmentReport"),
      name: "StudentAssignmentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/DetailReport",
      component: () => import("./views/DetailReport"),
      name: "DetailReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/ViewStudentAssignmentReport",
      component: () => import("./views/ViewStudentAssignmentReport"),
      name: "ViewStudentAssignmentReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/NewUser",
      component: () => import("./views/NewUser"),
      name: "NewUser",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/NewUsers",
      component: () => import("./views/NewUsers"),
      name: "NewUsers",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/FullLengthTest/:testId",
      component: () =>
        import(/* webpackChunkName: "manage-chapter-access" */ "./views/FullLengthTest"),
      name: "FullLengthTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: ["Demo Student", "Student"],
      },
    },
    {
      path: "/FilterManualTest",
      component: () => import("./views/FilterManualTest"),
      name: "FilterManualTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/CreateTestPreview",
      component: () => import("./components/CreateTest/CreateTestPreview"),
      name: "CreateTestPreview",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/LiveChat",
      component: () => import("./views/LiveChat"),
      name: "Live Chat",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/PublishSurvey",
      component: () => import("./views/PublishSurvey"),
      name: "Publish Survey",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/GroupNotification",
      component: () => import("./views/GroupNotification"),
      name: "GroupNotification",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.Student,
          RoleEnums.DemoStudent,
        ],
      },
    },
    {
      path: "/TestInstruction",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/TestInstructionPage"),
      name: "TestInstruction",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/FullLengthTestReport",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/FullLengthTestReport"),
      name: "FullLengthTestReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/SalesInvoiceForm",
      component: () => import("./views/SalesInvoiceForm"),
      name: "SalesInvoiceForm",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/FeedbackQuestionBank",
      component: () => import("./views/FeedbackQuestionBank"),
      name: "FeedbackQuestionBank",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/PracticeSessionNewReport/:UserTestSessionId",
      component: () => import("./views/PracticeSessionNewReport"),
      name: "PracticeSessionNewReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/AcademicPlanner",
      component: () => import("./views/AcademicPlanner.vue"),
      name: "AcademicPlanner",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.Student,
          RoleEnums.DemoStudent,
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.SchoolTeacher,
        ],
      },
    },
    {
      path: "/UploadLater",
      component: () => import("./views/UploadLater"),
      name: "Upload Later",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/CreateInstituteTest",
      component: () => import("./views/CreateInstituteTest"),
      name: "CreateInstituteTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/EditInstituteTestNew/:testId",
      component: () => import("./views/EditInstituteTestV1"),
      name: "EditInstituteTestNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/ManualTestPreview",
      component: () => import("./views/ManualTestPreview"),
      name: "ManualTestPreview",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/AutomaticTestPreview",
      component: () => import("./views/AutomaticQuesGenerationComponent"),
      name: "AutomMatic",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/SelfTestAnalysis",
      component: () =>
        import(/* webpackChunkName: "practice-analysis" */ "./views/SelfTestAnalysis"),
      name: "SelfTestAnalysis",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/SelfTestUserReport",
      component: () =>
        import(/* webpackChunkName: "practice-analysis" */ "./views/SelfTestUserReport"),
      name: "SelfTestUserReport",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/BatchCreation",
      component: () => import("./views/BatchCreation"),
      name: "BatchCreation",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/SchoolMapping",
      component: () => import("./views/SchoolMapping"),
      name: "SchoolMapping",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/EffortAnalysisDash",
      component: () => import("./views/EffortAnalysisDash"),
      name: "EffortAnalysisDash",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/SchoolSchedule",
      component: () => import("./views/SchoolScheduleV1"),
      name: "SchoolScheduleV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/Affiliation/lps",
      component: () => import("./views/affiliation/manageLPS"),
      name: "AffilationManageLPS",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/Student/lps",
      component: () => import("./views/StudentLpsListing"),
      name: "StudentLpsListing",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AddStudentTest",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/CreateSelfTest"),
      name: "CreateSelfTest",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/CourseMaterial",
      component: () => import("./views/CourseMaterial.vue"),
      name: "Course Material",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AssignmentListing",
      component: () => import("./views/AssignmentListing"),
      name: "AssignmentListing",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/ConductTestOld/:testUserId/:testType/",
      component: () => import(/* webpackChunkName: "conduct-test" */ "./views/ConductTest"),
      name: "ConductTestOld",
      meta: {
        hideNavbar: true,
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
      props: true,
    },
    {
      path: "/ReportList",
      component: () => import(/* webpackChunkName: "report-list" */ "./views/ReportList"),
      name: "ReportList",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/PracticeSessionNewReportV1/:UserTestSessionId",
      component: () => import("./views/PracticeSessionNewReport_V1"),
      name: "PracticeSessionNewReportV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [
          RoleEnums.AffiliationHead,
          RoleEnums.Faculty,
          RoleEnums.Student,
          RoleEnums.DemoStudent,
        ],
      },
    },
    {
      path: "/ConductTestPrevious/:testUserId/:testType/",
      component: () => import(/* webpackChunkName: "conduct-test" */ "./views/ConductTestBackup"),
      name: "ConductTestPrevious",
      meta: {
        hideNavbar: true,
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
      props: true,
    },
    {
      props: true,
      path: "/TestAnalysisReportNew",
      component: () =>
        import(/* webpackChunkName: "test-analysis-report-new" */ "./views/TestAnalysisReportNew"),
      name: "TestAnalysisReportNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      props: true,
      path: "/TestMarksheet",
      component: () => import(/* webpackChunkName: "test-marksheet" */ "./views/TestMarksheet"),
      name: "TestMarksheet",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/CreateAssignmentOld",
      component: () => import("./views/CreateAssignment"),
      name: "CreateAssignmentOld",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/AffiliationDetails",
      component: () => import("./views/AffiliationDetails"),
      name: "AffiliationDetails",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/CustomReports",
      component: () => import("./views/CustomReports"),
      name: "CustomReports",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/ClassSchedule",
      component: () => import("./views/affiliation/classSchedule.vue"),
      name: "ClassScheduleV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.CenterManager],
      },
    },
    {
      path: "/LiveClassesNew",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/LiveClassesNew"),
      name: "LiveClassNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/PracticeHistory",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/PracticeHistory"),
      name: "PracticeHistory",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent, "Deactive Demo Student"],
      },
    },
    {
      path: "/TutorHelpNew",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/TutorHelpNew"),
      name: "TutorHelpNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/ReviseQuestionsNew",
      component: () =>
        import(/* webpackChunkName: "add-student-test" */ "./views/ReviseQuestionsNew"),
      name: "ReviseQuestionsNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/QuestionView",
      component: () => import(/* webpackChunkName: "add-student-test" */ "./views/QuestionView"),
      name: "QuestionView",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/DailyPractice",
      component: () => import(/* webpackChunkName: "test-analysis" */ "./views/UserDppSessionNew"),
      name: "DppSessionNew",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/SubjectiveQuestionFeedback",
      component: () =>
        import(/* webpackChunkName: "test-analysis" */ "./views/SubjectiveQuestionFeedback"),
      name: "SubjectiveQuestionFeedback",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.Student, RoleEnums.DemoStudent],
      },
    },
    {
      path: "/AddFaculty",
      component: () => import("./views/AddFaculty"),
      name: "AddFaculty",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/AddFacultyBatch",
      component: () => import("./views/AddFacultyBatch"),
      name: "AddFacultyBatch",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead],
      },
    },
    {
      path: "/UserListing",
      component: () => import("./views/affiliation/roleWiseUserListing"),
      name: "RoleWiseUserListing",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/StudyMaterialNew",
      component: () => import("./views/StudyMaterialV1"),
      name: "StudyMaterialV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/Login",
      component: () => import("./views/Login"),
      name: "Login",
      meta: {
        hideNavbar: true,
        requiresAuth: false,
        scrollToTop: true,
      },
    },
    {
      path: "/Signup",
      component: () => import("./views/Signup"),
      name: "Signup",
      meta: {
        hideNavbar: true,
        requiresAuth: false,
        scrollToTop: true,
      },
    },
    {
      path: "/CoachingVideoNew",
      component: () => import("./views/CoachingVideoNewV1"),
      name: "CoachingVideoNewV1",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      props: true,
      path: "/TestAnalysisQuesWise",
      component: () => import("./views/TestAnalysisQuesWise"),
      name: "TestAnalysisQuesWise",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty],
      },
    },
    {
      path: "/WeeklyAssessment",
      component: () => import("./views/WeeklyAssessment"),
      name: "WeeklyAssessment",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
    {
      path: "/teachingcontent",
      component: () => import("./views/TeachingContent"),
      name: "studycontent",
      meta: {
        requiresAuth: true,
        scrollToTop: true,
        roles: [RoleEnums.AffiliationHead, RoleEnums.Faculty, RoleEnums.SchoolTeacher],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.loggedIn) {
      const roles = to.meta.roles || [];
      if (roles.includes(store.getters.user.role)) {
        next();
      } else {
        next({
          name: "NotAuthorized",
        });
      }
    } else {
      // window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
      next();
    }
  } else if (to.name === "CopyInstituteTest") {
    let constTestId = to.params.testId;
    if (constTestId == null) {
      constTestId = to.path.substring(to.path.lastIndexOf("/") + 1);
    }
    Api.copyInstituteTest(
      {
        testId: constTestId,
      },
      (response) => {
        if (response.errors.length) {
          window.M.toast({
            html: response.errors[0],
          });
          next();
        } else {
          window.M.toast({
            html: "Test copied successfully",
          });
          next({
            path: `/EditInstituteTest/${response.InstituteTestId}`,
            replace: true,
          });
        }
      },
      () => {},
    );
  } else if (to.name === "CopyInstituteTestV2" || to.path.includes("CopyInstituteTest")) {
    let constTestId = to.params.testId;
    if (constTestId == null) {
      constTestId = to.path.substring(to.path.lastIndexOf("/") + 1);
    }
    Api.copyInstituteTestV2(
      {
        testId: constTestId,
      },
      (response) => {
        if (response.errors.length) {
          window.M.toast({
            html: response.errors[0],
          });
          next();
        } else {
          window.M.toast({
            html: "Test copied successfully",
          });
          next({
            path: `/EditInstituteTest/${response.InstituteTestId}`,
            replace: true,
          });
        }
      },
      () => {},
    );
  } else {
    if (to.name === "PageNotFound") {
      // captureException(new Error("PageNotFound"), {
      //     extra: {
      //         params: JSON.stringify(from.params),
      //         path: from.path,
      //         query: from.query,
      //         hash: from.hash,
      //     },
      // });
    }
    if (to.name === "SSO") {
      if (to.query.token && to.query.redirectTo) {
        localStorage.setItem("SLToken", to.query.token);
        localStorage.removeItem("DashboardData");
        localStorage.removeItem("TeacherDashboardData");
        localStorage.removeItem("DashboardRefreshTime");
        localStorage.removeItem("practiceSelectedSubjectData");
        localStorage.removeItem("PracticeSessionRefreshData");
        localStorage.removeItem("testQuestionList");
        localStorage.removeItem("isPastYear");
        localStorage.removeItem("isDppSession");
        localStorage.removeItem("pastYearQuestionIndex");
        localStorage.removeItem("isSubjective");
        localStorage.removeItem("UserTestSessionId");
        if (to.query.ThirdPartySource) {
          localStorage.setItem("ThirdPartySource", to.query.ThirdPartySource);
        }
        window.location = to.query.redirectTo;
      } else if (!to.query.token) {
        localStorage.removeItem("SLToken");
        localStorage.removeItem("ThirdPartySource");
        localStorage.removeItem("DashboardData");
        localStorage.removeItem("TeacherDashboardData");
        localStorage.removeItem("DashboardRefreshTime");
        localStorage.removeItem("practiceSelectedSubjectData");
        localStorage.removeItem("PracticeSessionRefreshData");
        localStorage.removeItem("testQuestionList");
        localStorage.removeItem("isPastYear");
        localStorage.removeItem("isDppSession");
        localStorage.removeItem("pastYearQuestionIndex");
        localStorage.removeItem("UserTestSessionId");
        localStorage.removeItem("isSubjective");
        window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
      }
    }
    if (to.name === "Logout") {
      localStorage.removeItem("SLToken");
      localStorage.removeItem("ThirdPartySource");
      localStorage.removeItem("DashboardData");
      localStorage.removeItem("TeacherDashboardData");
      localStorage.removeItem("DashboardRefreshTime");
      localStorage.removeItem("practiceSelectedSubjectData");
      localStorage.removeItem("PracticeSessionRefreshData");
      localStorage.removeItem("testQuestionList");
      localStorage.removeItem("isPastYear");
      localStorage.removeItem("isDppSession");
      localStorage.removeItem("pastYearQuestionIndex");
      localStorage.removeItem("UserTestSessionId");
      localStorage.removeItem("isSubjective");
      window.location = `${process.env.VUE_APP_SL_URL}/Logout.aspx`;
    }
    next();
  }
});

router.isReady().then(() => {
  store.dispatch("routerReady");
});

export default router;
