import axios from "axios";
// import http-proxy-middleware from "http-proxy-middleware";
// const myHeaders = new Headers();

export default class Mobileapi {
  static init(router) {
    this.router = router;
    axios.interceptors.request.use(
      (config) => {
        const originalRequest = config;
        if (
          originalRequest.url.includes("otp") ||
          originalRequest.url.includes("get-boardDetails") ||
          originalRequest.url.includes("registration-student")
        ) {
          return Promise.resolve(originalRequest);
        }
        let token = "";
        if (localStorage && localStorage.getItem("SLToken")) {
          token = localStorage.getItem("SLToken") || "";
        }
        originalRequest.headers.Authorization = token ? `Bearer ${token}` : "";
        return Promise.resolve(originalRequest);
      },
      (error) => Promise.reject(error),
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
        }
        return Promise.reject(error);
      },
    );
  }

  static viewQuestionReport(instituteTestId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/InstituteTest/${instituteTestId}/Questions`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static studentCount(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/student-count`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static batchCount(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/batch-count`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static leaderboard(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/leader-board`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static batchperformance(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/batch-performance`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static globalperformance(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/global-performance`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getDataOfAffiliationCourses(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/get-affiliation-courses`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPracticeCoverageReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-practice-coverage`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestCoverageReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-institute-test-coverage`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestPerformanceReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-institute-test-performance`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPastWeekPracticeReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-last-week-practice`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentTest(userId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-assignments-new/${userId}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getExam(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-exam`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getExamQuestion(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-next-question-for-exam`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getExamSessionReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-exam-session-report`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static submitExamAnswer(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/submit-exam-answer`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static pauseExam(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/pause-exam`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static endExam(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/end-exam`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getSubjectListForCourse(affiliationCourseId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/courses/${affiliationCourseId}/subjects`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAffiliationBatchList(affiliationId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/${affiliationId}/batches`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getClassLogsList(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/get-classlogs`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAffiliationStudentList(affiliationId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/${affiliationId}/students`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAffiliationUserRoleList(affiliationId, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/${affiliationId}/users`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getAffiliationUserRoleDetail(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/rolewiselist`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getListOfTopics(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/Common/Topic`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },

        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getListOfChapters(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/Chapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },

        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getReviseQuestions(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/revision-question`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },

        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static bookMarkUnmarkQuestion(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/add-revision-question`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteBookMarkedQuestions(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/delete-revision-question`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static reportQuestion(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/report-error`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getLoginCredit(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/Login-credit`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async ExportQuestionSolutionPdf(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/get-Questions-pdf`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static PrintPage = async (Qdata) => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${localStorage.getItem("SLToken")}`);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const res = await fetch(
      `${process.env.VUE_APP_MOBILE_API_URL}/api/get-Questions-pdf-v1?instituteTestId=${Qdata.instituteTestId}&isAnswerReq=${Qdata.isAnswerReq}`,
      requestOptions,
    )
      .then((response) => response.arrayBuffer())
      .then((data) => data);
    const resText = JSON.parse(new TextDecoder().decode(res));

    if (resText.pdf === "absent") {
      window.M.toast({
        html: resText.message,
      });
    } else {
      const encryptedBytes = Buffer.from(resText.message);
      const b64 = Buffer.from(encryptedBytes).toString("base64");
      const tempLink = document.createElement("a");
      tempLink.href = `data:application/pdf;base64,${b64}`;
      tempLink.setAttribute("download", "InstituteTestPdfReport.pdf");
      tempLink.click();
    }
  };

  static affiliationFacultyMapping(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static StudentFeedback(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/add-feedback`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getcourseClassSubjectChapter(courseId, classId, success, failure) {
    // `/api/affiliation-batches/${AffiliationId}`
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/courses/${courseId}/class/${classId}/subjectchapter`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static AffiliationNotice(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/notice`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getStudentDetails(studentId, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/students/${studentId}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getFaculty(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getCourseClass(courseId, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/courses/${courseId}/class-list`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addbatch(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/addBatch`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getCourseChapterKscCluster(courseChapterId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/courses/coursechapter/${courseChapterId}/concepts`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getall(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/get-all`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getTutorHelpQuestions(data, courseId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/students/${courseId}/tutorHelpQuestions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static removeMarkForClarificationQuestion(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/students/${data}/removeTutorHelpQuestions`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getEntireDataOfQuestion(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/getQuestionById`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateCustomQuestion(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/updatecustomquestion`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getreport(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-report-details`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getreportdetails(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-selected-report`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getEvaluateSubjectiveQuestions(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/evaluate-subjective-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static teacherFeedBack(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/teacher-feedback`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAffiliationChapterBasedSubject(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/Topic`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAffiliationTopicBasedChapter(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/Chapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static AffiliationNoticeHistory(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/get-notice-list`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static AffiliationTestAnalysis(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-analysis`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static AffiliationTestAnalysisReport(testId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-analysis-test-report/${testId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static AffiliationTestAnalysisUserReport(userId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-analysis-user-report/${userId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static makeCashFreePayment(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/payment/makePayment`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static createRazorPayOrder(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/payment/razorpayOrder`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static updateRazorpayOrderStatus(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/payment/updateRazorpayOrderStatus`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCashFreeOrderDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/payment/paymentDetails`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static AffiliationSampleFile(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-sample-file`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static GetCourseChapter(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-course-chapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static createStandardAssignment(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/create-standard-assignment`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateCashFreePaymentDetails(success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/payment/updatePaymentDetails`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getCommonAssignmentQuestion(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-common-questions`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentList(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-assignment-list`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addAssignment(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/add`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static subjectiveQuestionEvaluation(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/subjective-question-evaluation?TeacherId=${data.TeacherId}&TeacherMarks=${data.TeacherMarks}&IsEvaluated=${data.IsEvaluated}&EvaluationPoints=${data.EvaluationPoints}&SubjectiveQuestionEvaluationId=${data.SubjectiveQuestionEvaluationId}`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getStudentForAssignment(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-student-for-bulkassignment`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static publishAssignment(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/publish-assignment`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static rescheduleAssignment(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/rescheduleAssignment`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static startExam(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/startExam`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getAssignmentUrl(PdfAssignmentId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/${PdfAssignmentId}/get-assignment-url`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentQuestion(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-assignments-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getUserById(userId, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/${userId}/getUser`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static UpdateUser(data, success, failure) {
    axios.put(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/updateUser`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getNewStudentDashboard(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/student_dashboard_new`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCourseBatchSubject(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-course-batch-subject`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCourseSubjectChapter(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/teacher/get-teacher-courseSubjectChapter?teacherId=${data.teacherId}&courseId=${data.courseId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getStudentAvgReport(UserId, SubjectId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/report/get-student-avreport/${UserId}/${SubjectId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getnextquestionpractice(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/get-nextQuestion`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static pauseSession(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/pause-session`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static Endsession(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/end-session`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static UpdateSession(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/update-session`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static UserTestErrorReport(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/update-session`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static MarkForClarification(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/update-session`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getVimeoVideo(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-vimeoVideo`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSelfTestList(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/StudentTest/testHistory`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestList(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/students/${data}/test-history`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInsituteTestIdByTestUserId(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-institute-test-userid?UserId=${data.UserId}&InstituteTestId=${data.InstituteTestId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCoinActivity(studentId, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/students/${studentId}/CoinActivity`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getClass(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-class`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateClass(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/change-class`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getallAcademicSessions(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-all-academicSessions`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static deleteBatch(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/deleteAffiliationBatch`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getOnlineClassStudent(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/online-classes`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static editOnlineClassStudent(data, success, failure) {
    axios.put(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/reschedule-classes`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addOnlineClassStudent(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getSubjectsByStudentId(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/students/${data}/subjects`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getcourseClassSubjectChapterForUser(userId, data, success, failure) {
    // `/api/affiliation-batches/${AffiliationId}`
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/courses/${userId}/subjectchapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getBatchDetails(batchId, success, failure) {
    // `/api/affiliation-batches/${AffiliationId}`
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/${batchId}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getUserProfile(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/profile`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static editBatch(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/editBatch`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static cancelAssignment(AssignmentLogId, success, failure) {
    axios
      .delete(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/${AssignmentLogId}/cancel-assignment`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static UpdateUserProfile(data, success, failure) {
    axios.put(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/updateUserProfile`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getTopicId(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-topicId`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getStudyMaterialDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/get-study-material-list`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addStudyMaterial(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/upload-study-material`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static downloadFileUpload(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/download-study-material/${data}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteFileUpload(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/delete-study-material`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static cancelbookOnlineClassStudent(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/cancel-liveClass/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static bookOnlineClassStudent(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/book-liveClass`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getQuestionTypeAvailableForTest(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/session/getPracticeMetadata`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static PracticeSessionSubjectiveType(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/submit-subjectiveQuestion`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getEvaluationPoints(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-evaluationPoints`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static PracticeUpdateSession(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/update-session`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static GetSubjectQuestionList(usertTestSessionId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/${usertTestSessionId}/get-subQnList`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getBoardDetails(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/get-boardDetails`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteVideos(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/video/search-coaching-video`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteCoachingVideo(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/video/delete-coaching-video`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getDashboardUpdateNeeded(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/check_dashboard_update`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSubjectCoverageData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/student_coverage_data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getTeacherDashboardData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-teacherdashboard-data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCalendarData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-calendar-data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addUpdateInstituteTest(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addUpdateInstituteTestV1(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-v1`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestDraft(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-Test-Drafts?AffiliationId=${data.AffiliationId}&CourseId=${data.CourseId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getBatches(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/List/batchList?AffiliationId=${data.AffiliationId}&CourseId=${data.CourseId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addInstituteTestMerge(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-merge`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteTestManualQuestion(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-manual-question-institute-test`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestListActions(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-Test-List-Actions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestListActionsV1(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-Test-List-Actions-v1`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static PublishInstituteTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-publish`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getPsychometryTestList(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/psycho/get-psychometry-test-list`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSelectedPsychometryTest(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/psycho/get-selected-psychometry-test`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static pausePsychometryTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/psycho/pause-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static submitPsychometryTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/psycho/submit-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static savePsychometryTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/psycho/save-question-answer`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addTimeOfPsychometryTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/psycho/add-time`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getServerTime(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-ServerTime`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static CopyInstituteTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/copy-institute-Test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async CopyInstituteTestv1(data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/copy-institute-Test-v1`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getInstituteTestDetails(instituteTestId, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-v1/${instituteTestId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static ViewInstituteTestQuestions(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-Test-questions`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static ViewInstituteTestQuestionsV1(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-Test-questions-v1`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static MarkForRevision(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/add-revision-question`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static RemoveRevision(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/delete-revision-question`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static QuestionMarkForClarification(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/clarification`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static GetInstituteTestSubjectiveQuestions(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/subjective`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static InstituteTestSubjectiveTypeSubmitQ(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/subjective`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static InstituteTestSubjectiveQuestionListSubmit(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/subjective/qns-list`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static SubmitSubjectiveQuestionList(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/userTest-session/submit-subjectiveQuestions-List`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getEffortUserReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/EffortAnalysis/UserReport`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getEffortAnalysisData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-effort-analysis-data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addCoachingVideo(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/upload-vimeo-video`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getChapterAccess(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/chapter-access`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static AddChapterAccess(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/add-chapter-access`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static RemoveChapterAccess(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/remove-ChapterAccess`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInsituteTestSubjectiveQuestionSolution(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-user-subjectiveques-solution`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getLatexQns(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/question-latex`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateTeacherFeedbackForInstSubjectiveWeb(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/web-teacher-feedback-for-instsubjective`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static searchSharedTests(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/shared/search-shared-tests`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getUserCourseSubjectChapter(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/get-teacher-courseSubjectChapter`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAllCourseSubjectsTopicsChapter(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/shared/get-metadata`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getSubjectiveInstituteTest(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-subjective-institute-test`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCourses(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-courses`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getTestCourseClasses(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-classes`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCourseClassSubjects(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-subjects`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCourseClassSubjectsTopics(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-topics`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCourseClassSubjectsTopicsChapters(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-chapters`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getBatchesSubjectsClasses(data, success, failure) {
    // axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-batches-subjects-classes`, { params: data })
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-batches-subjects-classes`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSubjectTopicChapter(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/subject-topic-chapter?courseId=${data.courseId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data[0]);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static MarkAsResolved(data, success, failure) {
    axios
      .put(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/userTestSessionQuestionId/${data}/clarifications`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCustomQuestionsList(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-customQuestions`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getStudentDoubt(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/${data.id}/clarifications?page=${data.page}&courseId=${data.courseId}&batch=${data.batch}&subjectId=${data.subjectId}&fromDate=${data.fromDate}&toDate=${data.toDate}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getStudentDashboardPlotly(data, success, failure) {
    // axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-batches-subjects-classes`, { params: data })
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/student_subject_trends`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentTestByDate(userId, courseId, fromDate, toDate, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-assignments/${userId}/${courseId}?fromDate=${fromDate}&toDate=${toDate}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-assignment-report`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getInstituteTestPartWiseUserExtensiveReport(data, success, failure) {
    await axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/getInstituteTestPartWise/userExtensiveReport`,
        {
          params: data,
        },
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async assignmentMarkForClarification(data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-tutor-help`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCommoneSubject(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-common-subjects`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async practiceSimilarQuestions(data, success, failure) {
    await axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/practice/usertest-session/get-similarquestion`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static async reGenerateTestReport(data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/institute-test-report`, data)
      .then(
        (response) => {
          if (success) {
            success(response);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static getInstituteTestReport(testId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/institute-test-report/${testId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getbatchreport(data, success, failure) {
    // axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-batches-subjects-classes`, { params: data })
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-batchsubject-coverage-data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAvReportdata(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-batch-avreport`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getPartWiseAllManualQuestions(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-all-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getAllCustomQuestions(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-all-custom-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addManageChapterAccess(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/access/manageChapterAccess?CourseId=${data.CourseId}&CenterIds=${data.CenterIds}&SubjectId=${data.SubjectId}&TopicId=${data.TopicId}&ChapterIds=${data.ChapterIds}`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAutoassignmentQuestionlist(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/get-autoassignment-questionslist`,
        {
          params: data,
        },
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentAnalysisDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/assignment-analyticsDetails`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getClassforbatch(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/check-classfor-batch`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static UpdateClassforStudent(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/change-class`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getSubjectDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-subjectDetails`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getBBBClassEnabled(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/bbb/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getFLTData(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/${data}/test`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async startFLTTest(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/start-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async submitFLTAnswer(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/test-response`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async endFullLengthTest(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/end-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getFLTTestListData(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/list/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static sendMessage(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/pusher/messages`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getZoomUrl(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/${data}/zakToken`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  // TODO: DELETE: Unused function + related APIs
  // static getRoleFeatures(data, success, failure) {
  //   axios.get(
  //     `${process.env.VUE_APP_MOBILE_API_URL}/api/common/featuresRoleWise?userId=${data.userId}`,
  //   )
  //     .then(
  //       (response) => {
  //         if (success) {
  //           success(response.data);
  //         }
  //       },
  //       (error) => {
  //         if (failure) {
  //           failure(error);
  //         }
  //       },
  //     );
  // }

  static getRoleMenu(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/menuRoleWise`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static createTestPreviewQuestions(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-full-length-test-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addUpdateInstituteTestV2(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institute-test-v2`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteTestSummary(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-institute-test-summary`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static replaceInstituteQuestions(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/replace-institutetest-question`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCreatTestManualQuestion(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-manual-question-institute-test`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateCreateTestInstructions(data, success, failure) {
    axios
      .put(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/update-institutetest-instruction`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteInstituteTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/delete-institute-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteTestTemplates(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-templates`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static deleteAffiliationSubscription(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/delete-affiliation-subscription`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static searchAffilations(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/search-affiliations`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static startNewPracticeSession(data, success, failure) {
    // axios.post("http://localhost:4000/api/practice/start-new-practice-session", data)
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/start-new-practice-session`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static pausePracticeSession(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/pause`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static resumePracticeSession(practiceId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/mongo/get-practice-data/${practiceId}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getInvoiceDetails(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-invoiceDetails`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getInvoiceHistory(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-invoice-history`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getPaymentModes(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/payment-mode`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addInvoiceDetails(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/add-invoice-details`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getProductPrice(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/product-price`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getSystemSettings(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/system-settings`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateInvoiceDetails(data, success, failure) {
    axios.put(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/update-invoice-details`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static endPracticeSession(sessionId, data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/session-report/${sessionId}`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getPracticeReportNew(sessionId, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/practice-report/${sessionId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getPracticeQuestion(userId, data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/questions/${userId}`, data)
      // await axios.post(`http://localhost:4000/api/practice/questions/${userId}`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static leadsLectureCategories(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/lecture-categories`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static leadsSubjectPlanner(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/subject-planner`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getAcademicPlannerData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/subject-planner`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAcademicAssessmentData(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/assessment-metadata`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static academicAssessmentScheduleData(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/assessment-schedule`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getCourseMetaData(affiliationId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/test-metadata-new/${affiliationId}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getBatchSchoolData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/school-metadata`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getTaskSchoolData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/partneronboarding/get-lead-tasks`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSLSPOC(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/search-spocname`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addOnBoarding(data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/submit-schoolonboarding-data`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addbatchForLead(affiliationId, data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/submit-batchcreation-data?affiliationid=${affiliationId}`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static editBatchForLead(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/editBatch`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getBatchTaskDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/get-TaskDetails/${data}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static updateBatchTaskDetails(batchId, data, success, failure) {
    axios
      .put(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/update-TaskDetails/${batchId}`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static removeBatchTaskDetails(TaskInstanceId, success, failure) {
    axios
      .delete(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/delete-TaskDetails/${TaskInstanceId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getRoleTypes(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/get-roletypes`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static deleteTaskDetails(BatchId, success, failure) {
    axios
      .delete(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/delete-batchtasks/${BatchId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addOtherInfo(AffiliationId, data, success, failure) {
    axios
      .post(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/submit-userinfo?AffiliationId=${AffiliationId}`,
        data,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCourseforLead(success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/get-Batchcreationdata`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getBatchDetailsLead(batchId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/affiliation/get-Batchdetails/${batchId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSelfTestAnalysisCenter(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/selftest-analysis-center?affiliationId=${data.affiliationId}&batchId=${data.batchId}&courseId=${data.courseId}&startDate=${data.startDate}&endDate=${data.endDate}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSelfTestUserData(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/test/selftest-analysis-user?userId=${data.userId}&startDate=${data.startDate}&endDate=${data.endDate}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPendingSessionData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/pending-session`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getLpsAffilationListingData(data, success, failure) {
    await axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/classes/lps/${data.id}/affiliation/${data.uid}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getTestUrl(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/dashboard/get-test-url`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getStudentDetailsForLps(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/lps/studentDetails/${data.id}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getSharedTestUserdata(success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/shared/shared-test-userdata`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getChapterStudentListForLps(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/lps/${data.id}/chapters/student`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getOnlineClassDetails(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/live-class/${data.id}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getSchoolEvents(success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/school-events`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getSchoolSchedulerDetails(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/school-schedule`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getFacultyListNew(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/get-FacultyList-New`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCoursesByAffiliationId(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/bots/getCourses-By-AffiliationId`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async deleteSchoolSchedule(data, success, failure) {
    await axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/school-schedule`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async deleteWeeklyPlannerData(data, success, failure) {
    await axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/weekly-planner`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getStudioList(success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/studio-list`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async addSchoolEvent(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/school-schedule`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async EditSchoolSchedule(data, success, failure) {
    await axios.put(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/school-schedule`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async shuffleQuestions(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/shuffle-questions`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async addStudentTestNew(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/student-test-v1`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getSelfTestQuestions(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/get-student-test-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getLpsStudentListingData(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/studentlps/${data.id}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getLpsQuestions(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/lps/get-lps-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async addSelfTestQuestions(data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/add-student-test-questions`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async validateOtp(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/validateOtp`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async submitTestAnswerToQueue(data, success, failure) {
    console.log(`${process.env.VUE_APP_SERVICEBUS}`);
    await axios.post(`${process.env.VUE_APP_SERVICEBUS}`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async verifyMobile(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/verifyMobile`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async verifyEmail(data, success, failure) {
    await axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/verifyEmail`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async deleteSelfTest(data, success, failure) {
    await axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/delete-student-test`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCourseChapterKsc(courseChapterId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/courses/coursechapter/${courseChapterId}/kscs`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCourseMaterial(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/course-material`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getPracticeNextQuestionNew(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/get-question-data`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInsitituteTestQuestions(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static startTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/start-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static submitAnswer(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/submit-answer`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static endTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/end-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getAssessmentData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/assessment-data`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getExamQuestions(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/exam-questions`, { params: data })
      // axios.get("http://localhost:4000/api/assignment/exam-questions", { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addExamRevisionQuestion(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/add-examrevision-question`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static removeExamRevisionQuestion(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/remove-examrevision-question`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static submitExamAnswerNew(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/submit-answer`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static liveclassdata(data, success, failure) {
    // axios.get("http://localhost:4000/api/classes", { params: data })
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getEventScheduleDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/event-schedule`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addUpdateWeeklyPlannerData(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/weekly-planner`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getBatchSchedulerData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/batch-scheduler`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static GetServerTime(data, success, failure) {
    axios.get(`${process.env.VUE_APP_SERVER_TIME}`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static markForClarification(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/mark-for-clarification`, data)
      // axios.post("http://localhost:4000/api/assignment/mark-for-clarification", data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getInstituteHistoryList(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/institutetest-history-list`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAffiliationData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-affiliation-details`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getEffortAnalysisPdf(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/get-effort-analysis-pdf-blob`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getExistingPdf(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/get-existing-pdf`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static createEffortAnalysisPdf(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_PDF}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteEffortAnalysisPdf(data, success, failure) {
    axios.delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/delete-pdf`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getPracticeSelfTestData(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-practice-selftest-data`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPracticeSelfTestDataBatchwise(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-practice-selftest-data-batchwise`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPracticeSelfTestDataChapterwise(data, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-practice-selftest-data-chapterwise`,
        { params: data },
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getLowAccuracyQs(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/admin/get-low-accuracy-qs`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getTestInstituteListData(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/getInstituteTestList`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async deleteAssessmentSchedule(data, success, failure) {
    await axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/assessment-schedule`, {
        params: data,
      })
      // await axios.delete("http://localhost:4000/api/lead/delete-assessment-schedule", { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getTestReport(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/report/test-report`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getStudentLiveClassDetailsNew(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/student-classes`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getPracticeHistoryNew(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/history`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getClassList(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/class-list`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static reportErrorQuestion(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/report-error-question`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getCourseChapterClusterKSCForClassLog(courseChapterId, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classlog/courseChapter/${courseChapterId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getCourseChaptersForClassLog(onlineClassDetailsId, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/${onlineClassDetailsId}/chapters`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getClassLogMetaData(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classlog/metadata`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addClassLog(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classlog`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async getClassDataForClass(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/classlog/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static async startDppSession(data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/practice/startDPPSession`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getStudentsNotPracticing(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/students-not-practicing`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static createOnlineClassForScheduler(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_PLANNER}`, {
        params: {
          ...data,
          token: localStorage.getItem("SLToken"),
        },
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getSubjectiveQnsForStudent(data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/user/subjective-qns`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async addClassNotes(data, success, failure) {
    await axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classlog/class-notes`, data)
      // await axios.post("http://localhost:4000/api/classlog/class-notes", data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static async getQuestionsFromQuestionBankPdf(pdfUuid, data, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/questionbank/questions/${pdfUuid}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addFacultyCourseSubjectMapping(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty-course-subject`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addFacultyBatchMapping(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty-batch`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getFacultyCourseSubjectMapping(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty-course-subject`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getFacultyBatchMapping(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty-batch`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteFacultyCourseSubjectMapping(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty-course-subject`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static deleteFacultyBatchMapping(data, success, failure) {
    axios
      .delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/faculty-batch`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static loginWithPassword(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/login/password`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static sendOTP(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/login/otp`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static verifyOTP(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/login/verify-otp`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static verifyOtpForRegistration(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/validate/verify-otp`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static sendOtpForRegistration(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/send-otp`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static registerStudent(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/authentication/registration-student`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getLecturePlannersForAffiliation(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/lecture-planner`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static checkDuplicateLecturePlanner(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/subject-planner/exists`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getTestAnalysisQuesWiseReport(testId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-analysis-ques-wise/${testId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCourseChapterMaterial(courseChapterId, success, failure) {
    axios
      .get(
        `${process.env.VUE_APP_MOBILE_API_URL}/api/courses/coursechaptermaterial/${courseChapterId}`,
      )
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getFileTypes(success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/affiliation/file/study-material-fileType`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static mapExistingInstituteVideo(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/institute-video/map`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteTestsForBatch(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/batch-institute-tests`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSectionsForTest(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-sections`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addInstituteTestManualMarks(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-manual-marks`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getTestCategory(success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-category`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getParentChildBatchesForAffiliation(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/lead/batches/parent-child`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getAssignmentsForAffiliation(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/affiliation`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getInstituteTestManualMarks(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/test/test-manual-marks`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getExamStatusForLPS(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/lps/status`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static createBBBMeeting(data, success, failure) {
    axios.post(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/bbb/createMeeting`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getBBBMeetingStatus(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/bbb/meeting`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static updateWeeklyAssignmentQuestions(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/questions/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getAssignmentDataToPublish(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/lps`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static cancelOnlineLiveClass(data, success, failure) {
    axios.delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/${data}/0`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static cancelAllOnlineLiveClass(data, success, failure) {
    axios.delete(`${process.env.VUE_APP_MOBILE_API_URL}/api/classes/${data}/1`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getClustersForCourse(data, success, failure) {
    axios.get(`${process.env.VUE_APP_MOBILE_API_URL}/api/common/course/clusters/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static copyAssignment(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_MOBILE_API_URL}/api/assignment/copy?assignmentLogId=${data}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }
}
