const RoleEnums = Object.freeze({
  SchoolTeacher: "SchoolTeacher",
  AffiliationHead: "Affiliation Head",
  Faculty: "Faculty",
  Student: "Student",
  DemoStudent: "Demo Student",
  CenterManager: "Center Manager",

});

export default RoleEnums;
