import axios from "axios";

export default class Api {
  static init(router) {
    this.router = router;

    // Add a request interceptor

    axios.interceptors.request.use(
      (config) => {
        const originalRequest = config;

        if (
          originalRequest.url.includes("otp") ||
          originalRequest.url.includes("get-boardDetails") ||
          originalRequest.url.includes("registration-student")
        ) {
          return Promise.resolve(originalRequest);
        }

        let token = "";

        if (localStorage && localStorage.getItem("SLToken")) {
          token = localStorage.getItem("SLToken") || "";
        } else {
          window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
        }

        originalRequest.headers.Authorization = token ? `Bearer ${token}` : "";

        return Promise.resolve(originalRequest);

        // return Promise.resolve(config);
      },
      (error) => Promise.reject(error),
    );

    // Add a response interceptor

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log("error response", error);

        if (error.response.status === 401) {
          console.log("redirect to login");

          window.location = `${process.env.VUE_APP_SL_URL}/Login.aspx`;
        }

        return Promise.reject(error);
      },
    );
  }

  static getProfile(success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/profile?_=${Math.random()}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteTestMeta(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/test-metadata`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstituteTest(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getManualQuestions(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-questionsv2`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getCustomQuestionsDetails(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-custom-questions`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addUpdateInstituteTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addUpdateInstituteTestV2(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-v2`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static copyInstituteTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/copy-institute-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static copyInstituteTestV2(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/copy-institute-test-v2`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addInstituteTestQuestion(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-questions`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addStudentTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/student-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static startTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/start-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getTest(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/test`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static submitAnswer(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/submit-answer`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static endTest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/end-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getStudentDashboard(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/student-dashboard`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static parentRequestsForStudent(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/parentRequests`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getPromotionEnabled(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/promotion-enabled`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getPromotion(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/promotion`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static addPromotion(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/promotion`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getSelectedSubjectData(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/dashboard-subject-data/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getTestReport(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/test-report`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getNotifications(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/notifications`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getLiveClasses(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/dashboard-live-classes`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static updateParentRequest(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/parent-request`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static bookLiveClass(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/book-liveclass`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static cancelLiveClass(data, success, failure) {
    axios.delete(`${process.env.VUE_APP_BACKEND_API_URL}/api/cancel-liveclass/${data}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getStates(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/states`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getCities(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/cities`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getdata(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-analysis`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static getsubdata(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/practice-analysis`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static institutedata(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-analysis-for-test`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static StudentData(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/user-test-analysis-for-test`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static async getDataOfAffiliationCourses(data, success, failure) {
    await axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/affiliation-courses`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static getCourses(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/affiliation-courses`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getBatches(AffiliationId, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/affiliation-batches/${AffiliationId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);

          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getListOfSubjects(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/get-subjects-forcourses/${data}`).then(
      (response) => {
        if (success) {
          success(response);
        }
      },
      (error) => {
        console.log(error);

        if (failure) {
          failure(error);
        }
      },
    );
  }

  static PartwiseInstituteTestReport(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/insitute-test-partwise`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);

          if (failure) {
            failure(error);
          }
        },
      );
  }

  static recordOfStudentsForPracticeAssignment(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/practice-analysis`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static StudentReport(AffiliationId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/student-test-report/${AffiliationId}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static StudentDoubtReport(AffiliationId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/student-doubt-report/${AffiliationId}`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static getAffiliationFacultyBatchSubjectMapping(AffiliationId, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/facultymapping/${AffiliationId}`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static InstituteTestList(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/report/institute-test-list`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static InstituteTestResult(data, success, failure) {
    axios
      .post(`${process.env.VUE_APP_BACKEND_API_URL}/api/report/institute-test-Result`, data)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static InstituteTestReport(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/report/institute-test-list`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static getPromotionDates(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/promotionDates`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static changePassword(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/affiliation-change-password`, data).then(
      (response) => {
        if (success) {
          success(response);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static psychometryTest(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/get-psychometry-quiz`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static psychometryTestPdfDownload(report, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/get-report-content?report=${report}`)
      .then(
        (response) => {
          if (success) {
            success(response);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static getSubjectsForCourses(courseId, data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/get-subjects-forcourses/${courseId}`)
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static getFeature(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/feature-details`, { params: data }).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        if (failure) {
          failure(error);
          console.log(error);
        }
      },
    );
  }

  static addOnlineClass(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes`, data).then(
      (response) => {
        if (success) {
          success(response);
        }
      },
      () => {
        if (failure) {
          failure();
        }
      },
    );
  }

  static async liveclassdata(upcoming, success, failure) {
    await axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes`, { params: upcoming })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          if (failure) {
            failure(error);
            console.log(error);
          }
        },
      );
  }

  static getAffLiveClassesList(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes-AffHeadFaculty`, {
        params: data,
      })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static cancelOnlineLiveClass(data, success, failure) {
    axios.delete(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes/${data}/0`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static cancelAllOnlineLiveClass(data, success, failure) {
    axios.delete(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes/${data}/1`).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static editOnlineClasses(data, success, failure) {
    axios.put(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes/`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static loginExternalUser(data, success, failure) {
    axios.get(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes/`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static registerExternalUser(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/online-classes/`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }

  static getInstTestUpcoming(data, success, failure) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-upcoming`, { params: data })
      .then(
        (response) => {
          if (success) {
            success(response.data);
          }
        },
        (error) => {
          console.log(error);
          if (failure) {
            failure(error);
          }
        },
      );
  }

  static addInstituteTestMerge(data, success, failure) {
    axios.post(`${process.env.VUE_APP_BACKEND_API_URL}/api/institute-test-merge`, data).then(
      (response) => {
        if (success) {
          success(response.data);
        }
      },
      (error) => {
        console.log(error);
        if (failure) {
          failure(error);
        }
      },
    );
  }
}
