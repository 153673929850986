<template>
    <div class="loader-overlay" v-if="this.$store.state.showLoader">
        <div class="valign-wrapper">
            <div class="valign">
                <div class="loader-wrapper"><div class="loader"></div></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    opacity: 2;
    height: 100vh;
    background-color: #00000070;
    z-index: 99999;
    display: block;
}
</style>
