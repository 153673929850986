import Vuex from "vuex";
import account from "./modules/account";

const debug = process.env.NODE_ENV !== "production";

const globalState = {
  showLoader: false,
  routerReady: false,
};

const getters = {
  showLoader: (state) => state.showLoader,
  routerReady: (state) => state.routerReady,
};

const mutations = {
  showLoader(state, data) {
    state.showLoader = data;
  },
  routerReady(state, ready) {
    state.routerReady = ready;
  },
};

const actions = {
  showLoader({ commit }, data) {
    commit("showLoader", data);
  },
  routerReady({ commit }) {
    commit("routerReady", true);
  },
};

export default Vuex.createStore({
  state: globalState,
  getters,
  actions,
  mutations,
  modules: {
    account,
  },
  strict: debug,
});
